import type { ClientMessage } from "./interfaces";
import axios from "axios";

/**
 * change locale language
 * @param language
 */
async function changeLocale(language: string) {
  const formData = new FormData();
  formData.append("language", language);
  axios.post("./client/changeLocale", formData).then((response) => {
    if (response.status !== 200) {
      console.error(`Request failed: ${response.statusText}`);
      return;
    }
    const data = response.data as ClientMessage;
    if (data.status === "error") {
      console.error(data.message);
    } else {
      console.info(`Current language: ${language}`);
      sessionStorage.setItem("language", language);
      window.location.reload();
    }
  });
}
document.addEventListener("DOMContentLoaded", () => {
  (document.getElementById("NL") as HTMLAnchorElement).addEventListener(
    "click",
    () => {
      changeLocale("NL");
    }
  );
  (document.getElementById("EN") as HTMLAnchorElement).addEventListener(
    "click",
    () => {
      changeLocale("EN");
    }
  );
});
